import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { generateTokenAuthHeader } from "../../commonfunctions/authentication";
import { log } from "../../commonfunctions/logger";
import Badge from "../../components/Badge/Badge";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import OrderForm from "../../components/OrderForm/OrderForm";
import { Container } from "../../components/styled/styled-components";
import { IContext, Context } from "../../context/ContextApp";
import { useDocumentTitle } from "../../hooks/document-title";
import Alert from "../../components/Alert/Alert";
import {
  ProductDescription,
  ProductDetailResponse
} from "../../interfaces/interfaces";
import { Brand } from "../../interfaces/types";
import ProductLink from "./ProductLink/ProductLink";
import { AlertType, SalesStatus } from "../../interfaces/enums";
import { subdomainTypes } from "../../context/availableSubdomains";

const ProductDetail = () => {
  const [product, setProduct] = useState<ProductDetailResponse | null>(null);
  const params = useParams<{ slug: string; brand: Brand }>();
  const location = useLocation();
  const { appBranch }: IContext = useContext(Context);
  useDocumentTitle(product ? `${product.code} ${product.name}` : params.slug);
  const preorderProducts = ['cst547us', 'cst547ls', 'cst546us', 'cst546ls'];

  const history = useHistory();
  const { push } = history;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (params.slug.includes("-promo")) {
      return push("/");
    }

    const fetchProductDetails = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HONEYBEE_ROOT_API}/products/${params.brand}/${appBranch}/${params.slug}`,
          generateTokenAuthHeader()
        );

        if (res.ok) {
          const data = await res.json();
          const product = data;
          if (product.code && product.brand) {
            setProduct(product);
          }
        } else {
          const error = await res.text();
          throw error;
        }
      } catch (error) {
        log(error, "Failed to fetch product details");
      }
    };

    fetchProductDetails();
  }, [push, params.slug, params.brand, appBranch]);

  if (product) {
    const isAlertActive = ["ZT460B", "ZT461B", "ZT462B"].includes(product.code)
    const christmasScrubs = ["CST346MS", "CST346LS"].includes(product.code)

    return (
      <Container row className="product-details">
        <div className="left-content">
          {product.images.length === 0 ? (
            product.colors[0].images.length > 0 ? (
              <img
                style={{ width: "100%" }}
                src={product.colors[0].images[0].https_attachment_url_product}
                alt="color_image"
              />
            ) : (
              <img
                style={{ width: "100%" }}
                src="https://cdn.fashionbiz.com/Image+Not+Found.jpg"
                alt="image_not_found"
              />
            )
          ) : (
            <img
              style={{ width: "100%" }}
              src={product.images[0].https_attachment_url_product}
              alt="product_image"
            />
          )}
          {appBranch && renderBadges(appBranch, product)}
          <div className="product-information">
            {getBrandLogo(product.brand)}
            {preorderProducts.includes(product.slug) && <div style={{color:"red"}}>Pre-Order now for October 2025  Delivery</div>}
            <h5>{product.name}</h5>
            <h5>{product.code} {christmasScrubs && <b>Limited Stock</b>}</h5>
          </div>
          <ProductLink stylesheetLink={product.stylesheet} slug={product.slug} brand={product.brand} />
          {product.description && (
            <div className="product-description">
              {renderJSONDescription_sizes(product.description)}
              {renderJSONDescriptionFields(
                "Fabric",
                product.description["fabric"]
              )}
              {renderJSONDescriptionFields(
                "Features",
                product.description["features"]
              )}
              {renderJSONDescriptionFields(
                "Cares",
                product.description["cares"]
              )}
            </div>
          )}
        </div>
        <div className="right-content">
          { isAlertActive && <Alert content={{type: AlertType.Warning, message: "Some garments may experience slight dye migration/smudging on upper back; fades out after washing."}} />}
          <OrderForm product={product} />
        </div>
      </Container>
    );
  } else {
    return (
      <div className="spinner-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
};

// If we need custom banners for certain countries, we need to include "appBranch: subdomainTypes | ''" in the arguments for this function
const renderBadges = (appBranch: subdomainTypes, product: ProductDetailResponse) => {

  const sales_statuses = [SalesStatus.Clearance, SalesStatus.Discontinued]
  const summerPromo = ['zs146', 'zs505', 'zp604', 'zp924', 'zs605', 'zs607', 'zp180',
    'zh235', 'zh236', 'zw229', 'zw400', 'zw405', 'zw460', 'zw465', 'zw760']
  const summerPromoMulti = ['zw765'];

  if ( product.slug.includes(SalesStatus.Clearance) || sales_statuses.includes(product.sales_status)) {
    return (
      <div>
        <Badge style={{ marginRight: "1em" }}>{product.sales_status}</Badge>
        <Badge>NON-RETURNABLE</Badge>
      </div>
    );
  }

  if (product.sales_status !== SalesStatus.Normal) {

    if(summerPromoMulti.some(promo => product.slug.includes(promo) && (appBranch === subdomainTypes.NZ || subdomainTypes.AU))){
      return (
        <div className="double-badge">
          <Badge>SUMMER PROMO</Badge>
          <br/>
          <Badge>NEW COLOUR</Badge>
       </div>
    )};

    return (
      <div>
        <Badge>{product.sales_status}</Badge>
      </div>
    );
  }

  if(summerPromo.some(promo => product.slug.includes(promo) && (appBranch === subdomainTypes.NZ || subdomainTypes.AU))){
    return (
      <div className="double-badge">
        <Badge>SUMMER PROMO</Badge>
      </div>
  )};
}

function renderJSONDescriptionFields(
  fieldName: string,
  descriptionJSON?: string[]
) {
  if (descriptionJSON && descriptionJSON.length > 0) {
    return (
      <div>
        <b>{fieldName}</b>
        {renderJSONValueForDescription(descriptionJSON)}
      </div>
    );
  }
}

function renderJSONDescription_sizes(description_JSON: ProductDescription) {
  if (description_JSON.sizes && description_JSON.sizes !== "") {
    return (
      <div style={{ display: "flex" }}>
        <b>Sizes</b> &nbsp; &nbsp;
        {renderJSONValueForDescription(description_JSON.sizes)}
      </div>
    );
  }
}

function renderJSONValueForDescription(value: string[] | string) {
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((item, index) => (
          <div key={index}>
            - <small>{item}</small>
          </div>
        ))}
      </>
    );
  } else {
    return <small style={{ lineHeight: "27px" }}>{value}</small>;
  }
}

const formatBrand = (brand: Brand) => {
  switch (brand) {
    case "biz-collection":
      return "Biz Collection";
    case "biz-corporates":
      return "Biz Corporates";
    case "syzmik":
      return "Syzmik";
    case "biz-care":
      return "Biz Care";
    case "direct-group":
      return "Direct Group";
    default:
      return "";
  }
};

const getBrandLogo = (brand: Brand) => {
  switch (brand) {
    case "biz-collection":
      return (
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/brand-logos/collection.svg"
          alt="brand-logo"
        />
      );
    case "biz-corporates":
      return (
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/brand-logos/corporates.svg"
          alt="brand-logo"
        />
      );
    case "syzmik":
      return (
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/brand-logos/syzmik.svg"
          alt="brand-logo"
        />
      );
    case "biz-care":
      return (
        <img
          src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/brand-logos/care.svg"
          alt="brand-logo"
        />
      );
    default:
      return <h1>{formatBrand(brand)}</h1>;
  }
};

export default ProductDetail;
