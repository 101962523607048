import React from "react";
import Login from "./login/Login";
import ResetPassword from "./reset-password/ResetPassword";

interface Props {
  reset?: boolean;
}

const AuthPage = ({ reset }: Props) => {

  const renderLogos = () => (
    <React.Fragment>
      <img
        height="100px"
        src="https://s3.ap-southeast-2.amazonaws.com/cdn.fashionbiz.com/webstore/Fashion+Biz+Logo.png"
        alt="fashion biz logo"
      />
    </React.Fragment>
  );

  return (
    <div className="auth-page">
      <div className="main-content">
        <div className="logos-section">{renderLogos()}</div>
        <div className="form-wrapper">
          {reset ? <ResetPassword /> : <Login />}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
